var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Zielvorgaben","isPreview":""}}),_c('section',[_c('TippCard',{attrs:{"hintID":"Terminplanung_Konfiguration_Allgemein_1_V1","text":"Veranstaltungen basieren auf einer Veranstaltungsart. Jeder Veranstaltungsart sind verschiedene Funktionen und Parameter zugewiesen, die sich teilweise auch auf bestehende Veranstaltungen auswirken können. Auf abgeschlossene Veranstaltungen haben Änderungen keine Auswirkung."}})],1),_c('section',[[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Subheader',{attrs:{"icon":"mdi-bullseye-arrow","title":"Zielvorgaben","actions":_vm.actions}}),_c('div',{staticClass:"mt-7"},[_c('v-data-iterator',{attrs:{"items":_vm.performanceTargets,"sort-by":"sortKey","no-data-text":"Keine Zielvorgaben vorhanden"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticClass:"match-height"},_vm._l((props.items),function(item){return _c('v-col',{key:item.meta.id,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"to":{
                          name: 'member-management-performance-targets-details',
                          params: {
                            organizationId: _vm.$route.params.organizationId,
                            itemId: item.meta.id,
                          },
                        }}},[_c('v-card-title',[_vm._v(_vm._s(item.title))]),_c('v-card-subtitle',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-numeric-ascending")]),_vm._v(" "+_vm._s(item.sortKey)+" ")],1),_vm._v(" Weitere Details ")]),_c('v-card-text',[_c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_vm._v(" Aktuelle Erfüllung ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":"green lighten-4","dark":"","disabled":_vm.value == 0,"outlined":""}},[_c('div',{staticClass:"font-weight-medium text-h5 text-center text--darken-4 green--text"},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_c('div',{staticClass:"font-weight-medium subtitle-2 text-center text--darken-4 green--text"},[_vm._v(" Erfüllt ")])])],1),_c('v-col',{attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":"red lighten-4","dark":"","disabled":_vm.value == 0,"outlined":""}},[_c('div',{staticClass:"font-weight-medium text-h5 text-center text--darken-4 red--text"},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_c('div',{staticClass:"font-weight-medium subtitle-2 text-center text--darken-4 red--text"},[_vm._v(" Nicht erfüllt ")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_vm._v(" Erfüllung letzte 14 Tage (DD.MM.YYYY bis DD.MM.YYYY) ")]),_c('apexchart',{attrs:{"type":"area","height":"200","options":_vm.chartOptions,"series":_vm.series}})],1)],1)],1)],1)],1)],1)}),1)]}}])})],1)],1)],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }